import React from 'react'
import Header from '../Header/Header'
import Home from '../../screens/Home/Home'
import About from '../../screens/About/About'
import Consult from '../../screens/Consult/Consult'
import Pricing from '../../screens/Pricing/Pricing'
import Footer from '../Footer/Footer'
import Services from '../../screens/Services/Services'
import Intro from '../../screens/Intro/Intro'
import Doctors from '../../screens/Doctors/Doctors'
import Blog from '../../screens/Blog/Blog'
import Company from '../../screens/Company/Company'

const Layout = () => {
	return (
		<>
			<Header />
			<Home />
			<About />
			{/* <Consult /> */}
			<Services />
			<Intro />
			<Doctors />
			<Company/>
			<Pricing />
			<Blog />
			<Footer />
		</>
	)
}

export default Layout