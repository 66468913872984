import React from 'react'
import { useTranslation } from 'react-i18next'
import './Intro.css'

const Intro = () => {
	const {t} = useTranslation()
	return (
		<>
			<section className="ftco-section intro bg-fixed"
    style={{backgroundImage: `url(https://images.pexels.com/photos/4483327/pexels-photo-4483327.jpeg?cs=srgb&dl=pexels-anna-shvets-4483327.jpg&fm=jpg)`, backgroundPosition: "50% 0%"}}
    data-stellar-background-ratio="1">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h3 className="mb-4">{t("promised")}</h3>
          {/* <p>{t("small_4")}</p> */}
        </div>
      </div>
    </div>
  </section>
		</>
	)
}

export default Intro