import React from 'react'
import image_1 from "../../images/image_1.jpg"
import image_2 from "../../images/image_2.jpg"
import image_3 from "../../images/image_3.jpg"
import { useTranslation } from 'react-i18next'

import './Footer.css'
const Footer = () => {
  const { t } = useTranslation()
  return (
    <>
      <footer id='contact' className="ftco-footer ftco-bg-dark ftco-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md">
              <div className="ftco-footer-widget mb-5">
                <h2 className="ftco-heading-2">{t("question")}</h2>
                <div className="block-23 mb-3">
                  <ul>
                    <li><span className="icon icon-map-marker"></span><span className="text">{t("address")}</span></li>
                    <li><a href="tel:+998781222333"><span className="icon icon-phone"></span><span
                      className="text">+998781222333</span></a></li>
                    {/* <li><a href="#"><span
                      className="icon icon-envelope"></span><span className="text">info@yourdomain.com</span></a></li> */}
                  </ul>
                </div>
                <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-3">
                  <li className="ftco-animatee"><a href="#"><span
                    className="icon-twitter"></span></a></li>
                  <li className="ftco-animatee"><a href="#"><span
                    className="icon-facebook"></span></a></li>
                  <li className="ftco-animatee"><a href="#"><span
                    className="icon-instagram"></span></a></li>
                </ul>
              </div>
            </div>
            <div className="col-md">
              <div className="ftco-footer-widget mb-5 ml-md-4">
                <h2 className="ftco-heading-2">{t("links")}</h2>
                <ul className="list-unstyled">
                  <li><a href="#"><span
                    className="ion-ios-arrow-round-forward mr-2"></span>{t("home")}</a></li>
                  <li><a href="#about"><span
                    className="ion-ios-arrow-round-forward mr-2"></span>{t("about")}</a></li>
                  <li><a href="#service"><span
                    className="ion-ios-arrow-round-forward mr-2"></span>{t("services")}</a></li>
                  <li><a href="#doctors"><span
                    className="ion-ios-arrow-round-forward mr-2"></span>{t("doctor")}</a></li>
                  <li><a href="#contact"><span
                    className="ion-ios-arrow-round-forward mr-2"></span>{t("contact")}</a></li>
                </ul>
              </div>
              {/* <div className="ftco-footer-widget mb-5 ml-md-4">
                <h2 className="ftco-heading-2">{t("services")}</h2>
                <ul className="list-unstyled">
                  <li><a href="#"><span
                    className="ion-ios-arrow-round-forward mr-2"></span>{t("neurology")}</a></li>
                  <li><a href="#"><span
                    className="ion-ios-arrow-round-forward mr-2"></span>{t("dentist")}</a></li>
                  <li><a href="#"><span
                    className="ion-ios-arrow-round-forward mr-2"></span>{t("ophthalmology")}</a></li>
                  <li><a href="#"><span
                    className="ion-ios-arrow-round-forward mr-2"></span>{t("cardiology")}</a></li>
                  <li><a href="#"><span
                    className="ion-ios-arrow-round-forward mr-2"></span>{t("surgery")}</a></li>
                </ul>
              </div> */}
            </div>
            <div className="col-md">
            <iframe src="https://yandex.uz/maps/10335/tashkent/?ll=69.244419%2C41.287420&mode=poi&poi%5Bpoint%5D=69.244268%2C41.287578&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D51608977821&z=20" frameborder="0" width={"300px"} height={"300px"}> </iframe>
            </div>
            <div className="col-md">
              <div className="ftco-footer-widget mb-5">
                <h2 className="ftco-heading-2">{t("open_hour")}</h2>
                <h3 className="open-hours pl-4"><span className="ion-ios-time mr-3"></span>{t("open")}</h3>
              </div>
              <div className="ftco-footer-widget mb-5">
                <h2 className="ftco-heading-2">{t("subscribe_us")}</h2>
                <form action="#" className="subscribe-form">
                  <div className="form-group">
                    <input type="text" className="form-control mb-2 text-center" placeholder={t("email")} />
                    <input type="submit" value={t("subscribe")} className="form-control submit px-3" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className='footer_copyright'>
        <h2>Copyright 2023</h2>
        <h2>{t("copyright1")} <a href='https://webmasters.uz'>Webmasters</a></h2>
      </div>
    </>
  )
}

export default Footer