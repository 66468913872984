import React from "react";
import blog1 from "../../images/blog1.png";
import blog2 from "../../images/blog2.png";
import "./Blog.css"
import { useTranslation } from "react-i18next";
const Blog = () => {
  const { t } = useTranslation()
  return (
    <>
      <div id="blog" className="ftco-section bg-light">
        <div className="container">
          <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section">
              <span class="subheading">{t("blog")}</span>
              <h2 class="mb-4">{t("recent_blog")}</h2>
            </div>
          </div>
          <div className="blog-wrap">
            <h2 className="blog-title">{t("pato")}</h2>
            <div className="blog1">
              <img
                src={blog1}
                alt="blog1"
              />
              <p>
              {t("blog_1")}
              </p>
            </div>
            <div className="blog2">
            <p>
              {t("blog_2")}
              </p>
              <img
                src={blog2}
                alt="blog1"
              />
            </div>
            {/* <div className="blog-1">
              <img
                className="h-[500px] w-full object-cover bg-center"
                src={blog1}
                alt=""
              />
              <p className="my-6">
               {t("blog_1")}
              </p>
            </div>
            <div className="blog-2">
              <img
                className="w-full h-[500px] object-cover bg-center"
                src={blog2}
                alt=""
              />
							<h1 className="text-[#46b7de] font-semibold my-4">{t("pato")}</h1>
              <p>
                {t("blog_2")}
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
