import React from 'react'
import { useState } from 'react'
import './Company.css'
import { useTranslation } from "react-i18next";
function Company() {
    const { t } = useTranslation()
    const tours = [
        {url : "tour1.jpg"},
        {url : "tour2.jpg"},
        {url : "tour3.jpg"}
    ]
    const [ tourActive, setTourActive ] = useState(0)
    return (
        <section className='company'>
            <h2 className='company-title'>{t("partner")} <a href='https://www.anadolumedicalcenter.ru'>Anadolu Medical Center</a></h2>
            <ul className='home_tour-wrapper'>
                {tours.map((tour, i) => (
                    <li
                        key={i}
                        style={{ backgroundImage: `url(${tour.url})` }}
                        role="button"
                        className={`home_tour-item ${tourActive === i ? 'active' : ''}`}
                        onClick={() => setTourActive(i)}
                    >
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default Company