import React from 'react'
import { useTranslation } from 'react-i18next'

const Pricing = () => {
	const {t} = useTranslation()
	return (
		<>
			<section id='pricing' className="ftco-section">
    <div className="container">
      <div className="row justify-content-center mb-5 pb-2">
        <div className="col-md-8 text-center heading-section ftco-animatee">
          <span className="subheading">{t("pricing")}</span>
          <h2 className="mb-4">{t("our_pricing")}</h2>
          {/* <p>{t("doc_desc")}</p> */}
        </div>
      </div>
      <div className="row items-stretch">
        <div className="col-md-3 items-stretch ftco-animatee">
          <div className="pricing-entry pb-5 text-center">
            <div>
              <h3 className="mb-4">{t("liquid")}</h3>
              <p><span className="price">от 250.000</span> <span className="per">/ sum</span></p>
            </div>
            {/* <ul>
              <li>{t("diagnostic")}</li>
              <li>{t("pro")}</li>
              <li>{t("tooth")}</li>
              <li>{t("surgical")}</li>
              <li>{t("teeth")}</li>
            </ul> */}
            <p className="button text-center"><a href="#"
                className="btn btn-primary px-4 py-3">{t("get")}</a></p>
          </div>
        </div>
        <div className="col-md-3 ftco-animatee">
          <div className="pricing-entry pb-5 text-center">
            <div>
              <h3 className="mb-4">{t("ngs")}</h3>
              <p><span className="price">от 32.000.000</span> <span className="per">/ sum</span></p>
            </div>
            {/* <ul>
              <li>{t("diagnostic")}</li>
              <li>{t("pro")}</li>
              <li>{t("tooth")}</li>
              <li>{t("surgical")}</li>
              <li>{t("teeth")}</li>
            </ul> */}
            <p className="button text-center"><a href="#"
                className="btn btn-primary px-4 py-3">{t("get")}</a></p>
          </div>
        </div>
        <div className="col-md-3 ftco-animatee">
          <div className="pricing-entry active pb-5 text-center">
            <div>
              <h3 className="mb-4">{t("histology")}</h3>
              <p><span className="price">от 500.000</span> <span className="per">/ sum</span></p>
            </div>
            {/* <ul>
              <li>{t("diagnostic")}</li>
              <li>{t("pro")}</li>
              <li>{t("tooth")}</li>
              <li>{t("surgical")}</li>
              <li>{t("teeth")}</li>
            </ul> */}
            <p className="button text-center"><a href="#"
                className="btn btn-primary px-4 py-3">{t("get")}</a></p>
          </div>
        </div>
        <div className="col-md-3 ftco-animatee">
          <div className="pricing-entry pb-5 text-center">
            <div>
              <h3 className="mb-4">{t("gisto")}</h3>
              <p><span className="price">от 2.200.000 </span> <span className="per">/ sum</span></p>
            </div>
            {/* <ul>
              <li>{t("diagnostic")}</li>
              <li>{t("pro")}</li>
              <li>{t("tooth")}</li>
              <li>{t("surgical")}</li>
              <li>{t("teeth")}</li>
            </ul> */}
            <p className="button text-center"><a href="#"
                className="btn btn-primary px-4 py-3">{t("get")}</a></p>
          </div>
        </div>

				<div className="col-md-3 ftco-animatee my-8">
          <div className="pricing-entry pb-5 text-center">
            <div>
              <h3 className="mb-4">{t("fish")}</h3>
              <p><span className="price">от 8.000.000 </span> <span className="per">/ sum</span></p>
            </div>
            {/* <ul>
              <li>{t("diagnostic")}</li>
              <li>{t("pro")}</li>
              <li>{t("tooth")}</li>
              <li>{t("surgical")}</li>
              <li>{t("teeth")}</li>
            </ul> */}
            <p className="button text-center"><a href="#"
                className="btn btn-primary px-4 py-3">{t("get")}</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>
		</>
	)
}

export default Pricing