import React from 'react'
import { useTranslation } from 'react-i18next'
import './Services.css'
import { LuSyringe } from 'react-icons/lu'
import { FaBriefcaseMedical } from 'react-icons/fa'
import { GiMedicalThermometer, GiMedicalDrip, GiMedicines } from 'react-icons/gi'

const Services = () => {
  const { t } = useTranslation()
  return (
    <>
      <section id='service' className="ftco-section ftco-services">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-2">
            <div className="col-md-8 text-center heading-section ftco-animatee">
              <span className="subheading">{t("services")}</span>
              <h2 className="mb-4">{t("our_serv")}</h2>
              {/* <p>{t("serv_desc")}</p> */}
            </div>
          </div>
          <section className='advantages'>
            <ul className='container'>
              <li className='list-item'>
                <div className='list-item_icon'><LuSyringe /></div>
                <p className='list-item_text'>
                  {t("dental_imp")}
                </p>
              </li>
              <li className='list-item'>
                <div className='list-item_icon'><FaBriefcaseMedical /></div>
                <p className='list-item_text'>
                  {t("cosmetic")}
                </p>
              </li>
              <li className='list-item'>
                <div className='list-item_icon'><GiMedicalThermometer /></div>
                <p className='list-item_text'>
                  {t("dental_care")}
                </p>
              </li>
              <li className='list-item'>
                <div className='list-item_icon'><GiMedicalDrip /></div>
                <p className='list-item_text'>
                  {t("teeth")}
                </p>
              </li>
              <li className='list-item'>
                <div className='list-item_icon'><GiMedicines /></div>
                <p className='list-item_text'>
                  {t("dental_calc")}
                </p>
              </li>
            </ul>
          </section>
        </div>
      </section>
    </>
  )
}

export default Services