import React from 'react'
import { useTranslation } from 'react-i18next'


const About = () => {
	const {t} = useTranslation()
	return (
		<>
			<section id='about' className="ftco-section ftco-no-pt ftco-no-pb">
    <div className="container">
      <div className="row">
        <div className="col-md-5 p-md-5 img img-2 mt-5 mt-md-0" style={{backgroundImage: `url(https://images.pexels.com/photos/4586993/pexels-photo-4586993.jpeg?cs=srgb&dl=pexels-anna-shvets-4586993.jpg&fm=jpg)`}}>
        </div>
        <div className="col-md-7 wrap-about py-4 py-md-5">
          <div className="heading-section mb-5">
            <div className="pl-md-5 ml-md-5 pt-md-5">
              <span className="subheading mb-2">{t("welcome")}</span>
              <h2 className="mb-2 text-[32px]">{t("medical")}</h2>
            </div>
          </div>
          <div className="pl-md-5 ml-md-5 mb-5">
            <p>{t("small")}</p>
            <p>{t("small_2")}</p>
            <div className="founder flex align-items-center mt-5">
              <div className="img" style={{backgroundImage: `url(https://docsd.anadolusaglik.org/doctor/crops/1920x700/prof_dr_37216.jpg)`}}></div>
              <div className="text pl-3">
                <h3 className="mb-0">{t("paul")}</h3>
                <span className="position">{t("ceo")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
		</>
	)
}

export default About