import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/animate.css';
import './styles/aos.css';
import './styles/bootstrap-datepicker.css';
import './styles/flaticon.css';
import './styles/icomoon.css';
import './styles/ionicons.min.css';
import './styles/jquery.timepicker.css';
import './styles/magnific-popup.css';
import './styles/open-iconic-bootstrap.min.css';
import './styles/owl.carousel.min.css';
import "./i18"




import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

