import React, { useState } from 'react'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import './Header.css'

const Header = () => {
	const [open, setOpen] = useState(false)
	const { i18n, t } = useTranslation()
	const locales = t("locales", { returnObjects: true }) || []
	const [local, setLocal] = useState(i18n.language)

	const dropdownMenu = locales.map(item => ({
		key: item.local,
		label: (
			<div
				onClick={() => {
					i18n.changeLanguage(item.local).then((r) => console.log(r))
					setLocal(item.local)
				}}
				className='flex gap-4 items-center justify-between'
			>
				<h1 className="text-[18px] font-medium">{item.label}</h1>
        <span className={`text-[28px] fi fi-${item.flag}`}> </span>
			</div>
		)
	}))
	return (
		<>
			<div className="py-md-5 py-4 border-bottom">
    <div className="container">
      <div className="row no-gutters d-flex align-items-start align-items-center px-3 px-md-0">
        <div className="logo col-md-4 order-md-2 mb-2 mb-md-0 align-items-center text-center">
          {/* <a className="navbar-brand" href=''>Abropothology<span style={{"margin":"10px"}}>{t("point")}</span></a> */}
          <img src='logo.png'/>
          <a href='https://www.anadolumedicalcenter.ru'>
            <img src='logo2.png'/>
          </a>
        </div>
        <div className="col-md-4 order-md-1 d-flex topper mb-md-0 mb-2 align-items-center text-md-right">
          <a href='https://yandex.uz/maps/10335/tashkent/?ll=69.244419%2C41.287420&mode=poi&poi%5Bpoint%5D=69.244268%2C41.287578&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D51608977821&z=20' className="icon d-flex justify-content-center align-items-center order-md-last">
            <span className="icon-map"></span>
          </a>
          <div className="pr-md-4 pl-md-0 pl-3 text">
            <p className="con"><span>{t("free_call")}</span> <span>+998781222333</span></p>
            <p className="con">{t("address")}</p>
          </div>
        </div>
        <div className="col-md-4 order-md-3 d-flex topper mb-md-0 align-items-center">
          <div  className="icon flex justify-content-center align-items-center"><span className="icon-paper-plane"></span>
          </div>
          <div className="text pl-3 pl-md-3">
            <p className="hr"><span>{t("open_hour")}</span></p>
            <p className="time"><span>{t("day_6")} </span> <span>8:30 - 18:00</span> {t("day_7")}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav className="navbar relative mb-0 rounded-none navbar-expand-lg navbar-dark bg-dark ftco-navbar-light" id="ftco-navbar">
    <div className="flex justify-between w-full items-start container">
			<div className='d-flex flex-col my-auto'>
      <button onClick={() => {setOpen(!open)}} className={`navbar-toggler ${open ? "" : "collapsed"}`} type="button" data-toggle="collapse" data-target="#ftco-nav"
        aria-controls="ftco-nav" aria-expanded={`${open ? "true" : "false"}`} aria-label="Toggle navigation">
        <span className="oi oi-menu"></span> Menu
      </button>
      <div className={`${open ? "" : "collapse"} navbar-collapse`} id="ftco-nav">
        <ul onClick={() => setOpen(!open)} className="navbar-nav m-auto">
          <li className="nav-item"><a href='#'
              className="nav-link pl-0">{t("home")}</a></li>
          <li className="nav-item"><a href='#about'
              className="nav-link">{t("about")}</a></li>
          <li className="nav-item"><a href='#doctors'
              className="nav-link">{t("doctor")}</a></li>
          <li className="nav-item"><a href='#service'
              className="nav-link">{t("service")}</a></li>
          <li className="nav-item"><a href='#pricing'
              className="nav-link">{t("pricing")}</a></li>
          <li className="nav-item"><a href='#blog' className="nav-link">{t("blog")}</a>
          </li>
          <li className="nav-item"><a href='#contact'
              className="nav-link">{t("contact")}</a></li>
        </ul>
      </div>
							</div>
			<Dropdown
			className='dropdown absolute right-[10%] top-6'
				menu={{ items: dropdownMenu }}
			>
				<div className=" flex justify-between items-center  cursor-pointer gap-x-3 navbar__locales z-[9999999]">
                <div className="flex items-center gap-x-[10px]  z-[9999999]">
                  <span
                    className={`text-[28px] fi fi-${
                      locales.find((x) => x.local === local)?.flag
                    }`}
                  ></span>
                </div>
                <i className="bx bx-chevron-down text-[#777] text-3xl"></i>
            </div>
			</Dropdown>
    </div>
  </nav>
		</>
	)
}

export default Header