import React from 'react'
import doc_1 from "../../images/doc-1.jpg"
import doc_3 from "../../images/doc-3.jpg"
import { useTranslation } from 'react-i18next'


const Doctors = () => {
	const {t} = useTranslation()
	return (
		<>
			<section id='doctors' className="ftco-section">
    <div className="container">
      <div className="row justify-content-center mb-5 pb-2">
        <div className="col-md-8 text-center heading-section ftco-animatee">
          <span className="subheading">{t("doctors")}</span>
          <h2 className="mb-4">{t("our_doc")}</h2>
          {/* <p>{t("doc_desc")}</p> */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-3 ftco-animatee">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div className="img align-self-stretch" style={{backgroundImage: `url(https://i.imgur.com/WSNc290.jpeg)`}}></div>
            </div>
            <div className="text pt-3 text-center">
              <h3>{t("dr_1")}</h3>
              <span className="position mb-2">{t("professor")}</span>
              <div className="faded">
                {/* <p>{t("dr_desc")}</p> */}
                <ul className="ftco-social text-center">
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-twitter"></span></a>
                  </li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-facebook"></span></a>
                  </li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span
                        className="icon-google-plus"></span></a></li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-instagram"></span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 ftco-animatee">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div className="img align-self-stretch" style={{backgroundImage: `url(https://docsd.anadolusaglik.org/doctor/crops/1920x700/prof_dr_88000.jpg)`}}></div>
            </div>
            <div className="text pt-3 text-center">
              <h3>{t("dr_2")}</h3>
              <span className="position mb-2">{t("professor")}</span>
              <div className="faded">
                {/* <p>{t("dr_desc")}</p> */}
                <ul className="ftco-social text-center">
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-twitter"></span></a>
                  </li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-facebook"></span></a>
                  </li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span
                        className="icon-google-plus"></span></a></li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-instagram"></span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 ftco-animatee">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div className="img align-self-stretch" style={{backgroundImage: `url(${doc_3})`}}></div>
            </div>
            <div className="text pt-3 text-center">
              <h3>{t("dr_3")}</h3>
              <span className="position mb-2">{t("patogistolog")}</span>
              <div className="faded">
                {/* <p>{t("dr_desc")}</p> */}
                <ul className="ftco-social text-center">
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-twitter"></span></a>
                  </li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-facebook"></span></a>
                  </li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span
                        className="icon-google-plus"></span></a></li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-instagram"></span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 ftco-animatee">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div className="img align-self-stretch" style={{backgroundImage: `url(${doc_1})`}}></div>
            </div>
            <div className="text pt-3 text-center">
              <h3>{t("dr_4")}</h3>
              <span className="position mb-2">{t("onkolog")}</span>
              <div className="faded">
                {/* <p>{t("dr_desc")}</p> */}
                <ul className="ftco-social text-center">
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-twitter"></span></a>
                  </li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-facebook"></span></a>
                  </li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span
                        className="icon-google-plus"></span></a></li>
                  <li className="ftco-animatee"><a href="#"
                      className="d-flex align-items-center justify-content-center"><span className="icon-instagram"></span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
		</>
	)
}

export default Doctors