import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Home.css"
import { useTranslation } from "react-i18next";

const Home = () => {
	const {t} = useTranslation()

	var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
  };
  return (
    <>
      <section className="home-slider owl-carousel z-[999] owl-loaded owl-drag">
        <Slider {...settings} className="relative">
            <div className="owl-item opacity-100">
              <div
                className="slider-item"
                style={{
									backgroundImage: `url(https://images.unsplash.com/photo-1514416309827-bfb0cf433a2d?auto=format&fit=crop&q=80&w=2071&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
								 	backgroundPosition: "center"
								}}
                data-stellar-background-ratio="0.5"
              >
                <div className="overlay"></div>
                <div className="container">
                  <div
                    className="row no-gutters slider-text align-items-center justify-content-end"
                    data-scrollax-parent="true"
                  >
                    <div className="col-md-6 text ftco-animate fadeInUp ftco-animated">
                      <h1 className="mb-4">
                        {t("helping")}
                      </h1>
                      <h3 className="subheading">
												{t("every_day")}
                      </h3>
                      <p>
                        <a href="#" className="btn btn-secondary px-4 py-3 mt-3">
                          {t("view_btn")}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-item opacity-100">
              <div
                className="slider-item"
                style={{backgroundImage: `url(https://images.unsplash.com/photo-1504813184591-01572f98c85f?auto=format&fit=crop&q=80&w=2071&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}
              >
                <div className="overlay"></div>
                <div className="container">
                  <div
                    className="row no-gutters slider-text align-items-center justify-content-end"
                    data-scrollax-parent="true"
                  >
                    <div className="col-md-6 text ftco-animate fadeInUp ftco-animated">
                      <h1 className="mb-4">
                        {t("smile")}  {t("lasting")}
                      </h1>
                      <h3 className="subheading">
                        {t("health")}
                      </h3>
                      <p>
                        <a href="#" className="btn btn-secondary px-4 py-3 mt-3">
                          {t("view_btn")}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </Slider>
      </section>
    </>
  );
};

export default Home;
